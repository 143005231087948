import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./NavBar";
import Hero from "./Hero";

function Design() {
  const [units, setUnits] = useState([]);
  const { design, type } = useParams();

  useEffect(() => {
    fetch("/api/files", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ design, type }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUnits(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [design, type]);

  return (
    <div>
      <NavBar />
      <Hero />
      <main id="portfolioMain">
        <section id="typeSections">
          <div id="portfolioCard">
            <div id="portfolioLinks">
              {units.map((unit, index) => (
                <a key={index} href={`${design}/${unit.folder}`}>
                  <img src={unit.imageUrl} alt="house interior" width="350px" />
                  <span>
                    {unit.folder.replace(/_/g, " ").charAt(0).toUpperCase() +
                      unit.folder.replace(/_/g, " ").slice(1)}
                  </span>
                </a>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Design;
