import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles.css";
import App from "./App";
import Admin from "./Admin";
import About from "./About";
import Portfolio from "./Portfolio";
import Design from "./components/Design";
import Unit from "./components/Units";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/:type/:design" element={<Design />} />
        <Route path="/portfolio/:type/:design/:unit" element={<Unit />} />
        <Route path="/maja" element={<Admin />} />
      </Routes>
    </React.StrictMode>
  </BrowserRouter>
);
