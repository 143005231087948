import React, { useState, useEffect } from "react";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import ReviewCard from "./components/ReviewCard";
import ReviewForm from "./components/ReviewForm";

function App() {
  const [reviews, setReviews] = useState([]);
  const [form, setForm] = useState(false);

  useEffect(() => {
    fetch("/api/approved/reviews")
      .then((response) => response.json())
      .then((data) => setReviews(data))
      .catch((error) => console.error("Error fetching reviews:", error));
  }, []);

  const leaveReview = () => {
    setForm(!form);
  };

  return (
    <div>
      <NavBar />
      <Hero />
      <main>
        <section id="whySection">
          <div style={{ overflowWrap: "break-word" }}>
            <h1 style={{ fontSize: "50px", margin: "0px" }}>Why Choose Us,</h1>
            <img
              src="/images/whyChoose.jfif"
              width="85%"
              alt="women on a laptop"
            />
          </div>
          <div>
            <h1 style={{ fontSize: "40px", margin: "15px 0px" }}>
              We're perfect fit
            </h1>
            <p style={{ fontSize: "20px" }}>
              Collaberate 1:1 with designer to create your perfect space. Let
              your designer guide you to a design that meets your functional
              needs, and suits your unqiue style.
            </p>
            <h1 style={{ fontSize: "40px", margin: "15px 0px" }}>
              Full peace of mind
            </h1>
            <p style={{ fontSize: "20px" }}>
              Enjoy all the fun of designing with none of the fuss. We take care
              of the style curation, budget tracking, project planning, sourcing
              and shopping.
            </p>
            <h1 style={{ fontSize: "40px", margin: "15px 0px" }}>
              A hands-free experience
            </h1>
            <p style={{ fontSize: "20px" }}>
              Our team handles every step of the delivery process: logistics,
              shipping, setup and clean up. All you need to do is open the door.
            </p>
          </div>
        </section>
        <section id="howSection">
          <div style={{ borderRight: "none" }}>
            <h1 style={{ fontSize: "30px", margin: "0px" }}>
              How ALL IN Process Works
            </h1>
          </div>
          <div style={{ borderRight: "none" }}>
            <h1 style={{ fontSize: "30px", margin: "0px" }}>Discovery</h1>
            <p style={{ fontSize: "20px" }}>
              We’ll set up a call to learn more about your furnishing needs,
              your style, your space and your budget.
            </p>
          </div>
          <div style={{ borderRight: "none" }}>
            <h1 style={{ fontSize: "30px", margin: "0px" }}>Design</h1>
            <p style={{ fontSize: "20px" }}>
              Our designers work closely with you to tailor every aspect of your
              design package until we achieve the perfect look.
            </p>
          </div>
          <div>
            <h1 style={{ fontSize: "30px", margin: "0px" }}>Delivery</h1>
            <p style={{ fontSize: "20px" }}>
              Your part’s done! We handle all the logistical details to get your
              items delivered and your space ready in as little as 4 weeks.
            </p>
          </div>
        </section>
      </main>
      <main style={{ backgroundColor: "#1b4965" }}>
        <section id="whatSection">
          <h1 style={{ fontSize: "50px", margin: "0px" }}>What do you get,</h1>
          <h1 style={{ fontSize: "50px", margin: "0px", marginBottom: "30px" }}>
            with ALL IN?
          </h1>
          <div id="whatCardHolder">
            <div className="whatImgCard" style={{ borderRight: "none" }}>
              <h1 style={{ fontSize: "30px", margin: "0px" }}>
                Simple pricing
              </h1>
              <p style={{ fontSize: "25px" }}>
                We create design packages that fit a range of budgets.
              </p>
            </div>
            <div className="whatImgCard" style={{ borderRight: "none" }}>
              <h1 style={{ fontSize: "30px", margin: "0px" }}>
                Quick turnaround
              </h1>
              <p style={{ fontSize: "25px" }}>
                Each design takes at most 2 days to create. Get in as many
                revisions as you need. Delivery will be within 2-4 weeks after
                purchase.
              </p>
            </div>
            <div className="whatImgCard">
              <h1 style={{ fontSize: "30px", margin: "0px" }}>Ten-day setup</h1>
              <p style={{ fontSize: "25px" }}>
                Furniture, decor, arts, lighting are all included into a design
                package. As well as linens, towels, kitchen essentials,
                electronics, and basically everything you need for the home.
              </p>
            </div>
          </div>
        </section>
      </main>
      <section id="reviewInfo">
        <h1 style={{ fontSize: "50px" }}>Our Happiness Guarantee</h1>
        <p style={{ fontSize: "25px" }}>
          If you’re not happy, we’re not happy. We know designing your home can
          be an intimidating experience, if you’re not happy with your design
          for whatever reason, let us know, and we’ll make it right.
        </p>
        <div id="reviewsBox">
          <div id="reviewsHero">
            <h1>Don't believe us?</h1>
            <h1>Look at what these customers have to say! </h1>
            <p>Want to leave a review? Click below</p>
            {form ? (
              <button onClick={leaveReview}>BACK TO REVIEWS</button>
            ) : (
              <button onClick={leaveReview}>LEAVE A REVIEW</button>
            )}
          </div>
          <div id="reviews">
            {form ? (
              <ReviewForm />
            ) : (
              reviews.map((reviewObj, index) => (
                <ReviewCard key={index} review={reviewObj} />
              ))
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;
